<!-- Hero Start -->

<section class="bg-half-170 d-table w-100  overflow-hidden" id="home" style="padding-bottom: 20px !important; padding-top: 100px;">
    <div class="contenedor">
        <img src="assets/images/logopeda-bilbao/Portada_Logopeda_bilba.webp" alt="Consulta de Psicología en Bilbao" class="image">
        <div class="overlay">
          <h2 class="headline">Centro MÉDICOS BILBAO</h2>
          <h1 class="headline">CONSULTA LOGOPEDIA EN BILBAO</h1>
        </div>
      </div>
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-md-12">
                <div class="title-heading">                    
                    <p>En el centro <strong><a href="https://medicosbilbao.com" target="_blank">MÉDICOS BILBAO</a></strong>, nuestra logopeda ayuda a pacientes de todas las edades a superar dificultades en el habla, voz, comunicación, audición, lenguaje y deglución, ya sea debido a trastornos del desarrollo, lesiones cerebrales, condiciones médicas o cualquier otra causa.</p>
                    
                    <p>La <strong>LOGOPEDIA</strong> es una disciplina vital que aborda trastornos relacionados con el habla, el lenguaje, la voz y la comunicación.</p>                    
                    
                    <h3 class="text-center">LOGOPEDA EN BILBAO</h3>
                    <p>En <strong><a href="https://medicosbilbao.com" target="_blank">MÉDICOS BILBAO</a></strong> contamos con <strong>Adela Puente</strong>, una experimentada logopeda, que dirige nuestra a consulta de <strong>Logopeda Infantil en Bilbao</strong>, para atender los problemas de los más pequeños y adolescentes. </p>
                    
                    <p><strong>Adela</strong> también posee experiencia como logopeda atendiendo problemas en pacientes adultos. Por ello en nuestra consulta de <strong>Logopeda de Adultos  en Bilbao</strong>, Adela evalúa y trata así mismo trastornos de logopedia más específicos de personas adultas y mayores.</p>                 
                    </div>
            </div>           
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section bg-light" style="padding-top: 20px !important; padding-bottom:20px !important;">
    <div class="container">
        <div class="row">           

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   <div class="card-body p-0 content">
                        <h5>Centro <br> Médicos Bilbao</h5>
                        <a href="https://medicosbilbao.com/"  target="_blank" class="btn btn-pills btn-soft-success mt-4">Ir a la web</a>         
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Nuestra <br>Logopeda</h5>                        
                        <a href="https://medicosbilbao.com/quienes-somos/adela-puente-logopeda-bilbao" target="_blank" class="btn btn-pills btn-soft-success mt-4">Adela Puente</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                 
                    <div class="card-body p-0 content">
                        <h5>Dónde<br> Estamos</h5>
                              <a href="https://medicosbilbao.com/contactar" target="_blank" class="btn btn-pills btn-soft-success mt-4">Ver Mapa</a>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Reservar <br>Cita</h5>                        

                            <a href="https://medicosbilbao.com/contactar" class="btn btn-pills btn-soft-success mt-4" data-bs-toggle="modal"
                            data-bs-target="#appointment-request">Ir a contactar</a>

                       
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section" style="padding-top: 20px ; padding-bottom:20px ;">

    <div class="container ">
        <h4 class="title mb-4 text-center">NUESTRA CONSULTA DE LOGOPEDA EN BILBAO</h4>
                          
        <p>Nuestra consulta de <strong>logopeda en Bilbao</strong> está ubicada en plena <strong>Gran Vía Don Diego López de Haro 81</strong>, con un acceso inmejorable, cerca del metro, tranvía, autobús, parada de taxi y parking público. El trato cercano y personalizado de nuestro experimentado equipo así como poder contar con unas modernas y cómodas instalaciones son la base para que te sientas como en casa cuando nos visites.</p>
      
                          <div class="row">
                              <div class="col-lg-6 order-2 order-lg-1 p-0">
                                  <div class="h-100 m-0">
                                      <div class="row m-0">
                                          <div class="col-half-section col-half-section-right py-2 ms-auto">
                                              <div class="p-3">
                                                  <h3 class="font-weight-semibold ls-0 text-color-default text-4 mb-0   ">Logopeda infantil en Bilbao</h3>
                                                  
                                                  <p class="text-3-5 pb-2  text-justify"      >
                                                      <p>Nuestra logopeda infantil tiene amplia experiencia en ayudar a niños con dificultades comunicativas, como problemas de aprendizaje, desarrollo del lenguaje, lectura, fluidez verbal, autismo y TDAH.</p><p>

                                                        Sabemos que tu hijo es tu prioridad, por eso me dedico con pasión a acompañarlo en su proceso de mejora.
                                                        </p>                    
                                                  
                                                  <a href="https://medicosbilbao.com/especialidades/logopeda-bilbao" class="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="600" data-plugin-options="{'minWindowWidth': 0}">Más Info</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-6 order-1 order-lg-2 p-0">
                                  
                                  <img src="assets/images/logopeda-bilbao/Logopeda_infantil_bilbao.webp" class="img-fluid" loading="lazy" alt="Logopeda Infantil">
                                 
                              </div>
                          </div>	
      
                          <div class="row" >                    
                              <div class="col-lg-6 p-0">
                                 
                                  <img src="assets/images/logopeda-bilbao/Logopeda_adultos_bilbao.webp" class="img-fluid" loading="lazy" alt="Logopeda adultos">
      
                              </div>
                              <div class="col-lg-6 p-0">
                                  <div class="h-100 m-0">
                                      <div class="row m-0">
                                          <div class="col-half-section col-half-section-left py-2">
                                              <div class="p-3">
                                                  <h3 class="font-weight-semibold ls-0 text-color-default text-4 mb-0   ">Logopeda adultos en Bilbao</h3>
                                                  
                                                  <p class="text-3-5 pb-2 text-justify"     > <p>Nuestra logopeda evalúa y trata trastornos del habla, lenguaje y comunicación que afectan a personas adultas y mayores, como afonías, disfonías, afasias, dificultades derivadas de enfermedades neurodegenerativas, alteraciones del habla y la rehabilitación comunicativa posterior a un accidente cerebrovascular.</p><p>
                                                    Nuestra logopeda diseña un plan de tratamiento personalizado con los pacientes que mejore sus habilidades comunicativas y optimice su calidad de vida.
                                                    </p>
                  
                                                  <a href="https://medicosbilbao.com/especialidades/logopeda-infantil-bilbao" class="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="600" data-plugin-options="{'minWindowWidth': 0}">Más Info</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>     
                      </div>
    

    <div class="container mt-20">
        <div class="row align-items-end mb-4 pb-2">
            <div class="col-md-10">
                <div class="section-title  text-md-start">
                    
                  
                   <h2 class="text-center">PROBLEMAS QUE TRATAMOS EN NUESTRO CENTRO DE LOGOPEDIA EN BILBAO</h2>

                 
                </div>
            </div>
            <!--end col-->

           
            <!--end col-->
        </div>
        <!--end row-->


        <div class="row"> 
            <div >
                <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                    class="nav nav-pills rounded shadow p-3 mb-0 col-md-12 list-inline  list-inline-columns" orientation="horizontal">
                    <li ngbNavItem="top" class="nav-item list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Trastornos del habla</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="logopedia">
                                <img src="assets/images/logopeda-bilbao/habla.webp" class="rounded shadow" style="width:100%" alt="Trastornos del habla" loading="lazy">
                                <div class="mt-4">
                                    <h5>Trastornos del habla</h5>
                                    <p class="text-muted my-3"><strong>Los logopedas evalúan y tratan una amplia gama de trastornos del habla</strong> que afectan la producción de sonidos, la fluidez, la resonancia y la articulación del lenguaje. Algunos de los trastornos del habla más comunes que pueden ser abordados por un logopeda incluyen:

                                       

                                </p>

                                <ul>
                                    <li><strong>Bradifemia o bradilalia:</strong> Habla lenta y pausada.</li>
                                    <li><strong>Disartria:</strong> Dificultad para articular las palabras debido a problemas neurológicos o musculares.</li>
                                    <li><strong>Disfemia o tartamudez:</strong> Repetición o bloqueo involuntario del habla.</li>
                                    <li><strong>Disglosia (labio leporino, fisuras, parálisis facial…):</strong> Dificultad para hablar debido a malformaciones bucofaciales.</li>
                                    <li><strong>Dislalia:</strong> Dificultad para pronunciar correctamente ciertos sonidos del habla.</li>
                                    <li><strong>Dispraxia verbal:</strong> Dificultad para planificar y coordinar los movimientos necesarios para hablar.</li>
                                    <li><strong>Farfulleo:</strong> Habla rápida y confusa.</li>
                                    <li><strong>Retraso del habla:</strong> Desarrollo del lenguaje más lento de lo normal.</li>
                                    <li><strong>Taquifemia o taquilalia:</strong> Habla rápida y excesivamente fluida.</li>
                                    <li><strong>Trastorno fonológico:</strong> Dificultad para comprender o utilizar las reglas del sonido del lenguaje.</li>
                                </ul>
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    
                    <li ngbNavItem="middle" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Trastornos del lenguaje</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="logopedia">
                                <img src="assets/images/logopeda-bilbao/lenguaje.webp" class="rounded shadow" style="width:100%" alt="Trastornos del lenguaje" loading="lazy">
                                <div class="mt-4">
                                    <h5>Trastornos del lenguaje</h5>
                                    <p class="text-muted my-3"><strong>Los logopedas evalúan y tratan una amplia gama de trastornos del lenguaje que afectan la comprensión y el uso del lenguaje hablado y escrito</strong>. Algunos de estos trastornos del lenguaje abordados por un logopeda son:</p>

                                    <ul>
                                        <li><strong>Afasia:</strong> Un trastorno del lenguaje causado por daño cerebral que afecta la capacidad de comprender, hablar, leer o escribir.</li>
                                        <li><strong>Disfasia o trastorno específico del lenguaje (TEL):</strong> Un trastorno del lenguaje que afecta la capacidad de comprender y utilizar el lenguaje, sin que haya otras causas como problemas auditivos, intelectuales o motrices.</li>
                                        <li><strong>Mutismo:</strong> Un trastorno que se caracteriza por la ausencia total de habla, sin que haya causas físicas que lo expliquen.</li>
                                        <li><strong>Retraso del lenguaje:</strong> Un desarrollo del lenguaje más lento de lo normal, sin que haya otras causas como problemas auditivos, intelectuales o motrices.</li>
                                        <li><strong>Trastorno del espectro autista (TEA):</strong> Un trastorno del desarrollo neurológico que afecta la forma en que las personas se comunican, interactúan y se comportan.</li>
                                    </ul>
                                    
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="bottom" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Disfunciones orofaciales</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="logopedia">
                                <img src="assets/images/logopeda-bilbao/orofaciales.webp" class="rounded shadow"  style="width:100%" alt="Disfunciones orofaciales" loading="lazy">
                                <div class="mt-4">
                                    <h5>Disfunciones orofaciales</h5>
                                    <p  class="text-muted my-3"><strong>Los logopedas, como especialistas en Terapia Miofuncional, están capacitados para evaluar y tratar una amplia gama de disfunciones orofaciales, las cuales afectan el funcionamiento normal de la boca, la cara y sus estructuras asociadas. Estas disfunciones pueden impactar negativamente en la respiración, la masticación, la deglución, el habla y la estética facial.</strong></p>

<ul>
    <li><strong>Babeo (sialorrea):</strong> Secreción excesiva de saliva que se escapa por la boca. Puede ser causada por problemas glandulares, neurológicos o por hábitos inadecuados como la succión del dedo o la respiración bucal.</li>
    <li><strong>Deglución atípica:</strong> Patrón de deglución incorrecto en el que la lengua empuja contra los dientes o encías al tragar. Esto puede ocasionar problemas dentales, atragantamientos y dificultad para hablar.</li>
    <li><strong>Disfagia:</strong> Dificultad para tragar alimentos o líquidos. Puede ser causada por obstrucciones en la garganta, problemas neurológicos o trastornos musculares.</li>
    <li><strong>Hipotonía:</strong> Disminución del tono muscular que afecta la fuerza, la coordinación y el movimiento. Puede afectar la masticación, la deglución y el habla.</li>
    <li><strong>Incompetencia labial:</strong> Incapacidad para cerrar los labios completamente. Puede ocasionar sequedad bucal, problemas dentales y dificultad para hablar.</li>
    <li><strong>Mordida abierta:</strong> Cuando los dientes incisivos superiores e inferiores no se contactan al cerrar la boca. Puede ser causada por factores hereditarios, hábitos inadecuados o problemas de crecimiento.</li>
    <li><strong>Respiración bucal:</strong> Respiración por la boca en lugar de la nariz. Puede ocasionar sequedad bucal, problemas dentales, alteraciones del sueño y dificultad para tragar.</li>
</ul>
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="general" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Trastornos auditivos</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="logopedia">
                                <img src="assets/images/logopeda-bilbao/auditivos.webp" class="rounded shadow" style="width:100%" alt="Trastornos auditivos" loading="lazy">
                                <div class="mt-4">
                                    <h5>Trastornos auditivos</h5>
                                    <p class="text-muted my-3"><strong>Los logopedas pueden desempeñar un papel importante en el tratamiento de diversos trastornos auditivos</strong>, especialmente aquellos que afectan la comunicación y el desarrollo del lenguaje, ayudando a la capacidad de discriminar sonidos, seguir instrucciones y comprender el lenguaje en contextos complejos.</p>

                                    <p><strong>Por ello pueden ayudar en el tratamiento de:</strong></p>
                                    
                                    <ul>
                                        <li><strong>Dispraxia verbal:</strong> Dificultad para planificar y coordinar los movimientos necesarios para producir el habla.</li>
                                        <li><strong>Hipoacusia:</strong> Pérdida auditiva de leve a profunda que puede afectar la capacidad para comprender el habla.</li>
                                        <li><strong>Retraso del lenguaje debido a la pérdida auditiva:</strong> Retraso en el desarrollo del lenguaje causado por pérdida auditiva no detectada o no tratada a tiempo.</li>
                                        <li><strong>Sordera unilateral:</strong> Pérdida auditiva en un solo oído.</li>
                                        <li><strong>Tinnitus:</strong> Zumbido constante o tinnitus en los oídos.</li>
                                        <li><strong>Trastorno del procesamiento auditivo central (TPAC):</strong> Dificultad para procesar y comprender la información auditiva, incluso cuando la audición es normal.</li>
                                    </ul>
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="medicine" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Trastornos del aprendizaje</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="logopedia">
                                <img src="assets/images/logopeda-bilbao/aprendizaje.webp" class="rounded shadow" style="width:100%" alt="Trastornos del aprendizaje" loading="lazy"> 
                                <div class="mt-4">
                                    <h5>Trastornos del aprendizaje</h5>
                                    <p class="text-muted my-3"><strong>Los logopedas evalúan y tratan diversos trastornos del aprendizaje que afectan la comunicación y el lenguaje</strong>. Un logopeda puede abordar los siguientes trastornos del aprendizaje más comunes:</p>

                                    <ul>
                                        <li><strong>Agrafía:</strong> Dificultad para expresar ideas por escrito.</li>
                                        <li><strong>Alexia:</strong> Incapacidad para leer, o lectura con dificultad.</li>
                                        <li><strong>Discalculia:</strong> Dificultad para comprender y utilizar los números.</li>
                                        <li><strong>Disgrafía:</strong> Dificultad para escribir de forma legible y correcta.</li>
                                        <li><strong>Dislexia:</strong> Dificultad para leer y comprender lo escrito.</li>
                                        <li><strong>Disortografía:</strong> Dificultad para escribir palabras de forma ortográfica correcta.</li>
                                        <li><strong>Hiperlexia:</strong> Capacidad para leer con fluidez a una edad temprana, pero con poca comprensión.</li>
                                        <li><strong>Retraso lecto-escritor:</strong> Desarrollo lento de la lectura y la escritura en comparación con la norma.</li>
                                        <li><strong>Dificultades en la comprensión lectora:</strong> Dificultad para entender lo que se lee.</li>
                                    </ul>
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="orthopadic" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Trastornos de la voz</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="logopedia">
                                <img src="assets/images/logopeda-bilbao/voz.webp" class="rounded shadow"  style="width:100%" alt="Trastornos de la voz" loading="lazy"> 
                                <div class="mt-4">
                                    <h5>Trastornos de la voz</h5>
                                    <p class="text-muted my-3"><strong>Los logopedas están capacitados para evaluar y tratar una amplia gama de trastornos de la voz, que incluyen:</strong></p>

                                    <p><strong>Disfonías:</strong></p> 
                                    <ul>
                                        <li><strong>Disfonía funcional:</strong> Alteración de la voz causada por un uso inadecuado de la voz, como hablar en voz alta durante largos periodos, gritar o cantar sin técnica adecuada.</li>
                                        <li><strong>Nódulos vocales:</strong> Crecimientos benignos en las cuerdas vocales que pueden causar ronquera y dificultad para hablar.</li>
                                        <li><strong>Pólipos vocales:</strong> Crecimientos anormales en las cuerdas vocales que pueden causar ronquera y disfonía.</li>
                                        <li><strong>Quistes vocales:</strong> Bolsas llenas de líquido que se forman en las cuerdas vocales y pueden causar ronquera y disfonía.</li>
                                        <li><strong>Parálisis vocal:</strong> Debilidad o parálisis de una o ambas cuerdas vocales, lo que puede provocar ronquera, disfonía o afonía.</li>
                                        <li><strong>Hipoacusia:</strong> Pérdida auditiva que puede afectar la producción de la voz.</li>
                                    </ul>
                                    
                                    <p><strong>Rinofonías (hipernasalidad e hiponasalidad):</strong></p>
                                    <ul>
                                        <li><strong>Rinofonía hipernasal:</strong> Voz nasal excesiva causada por problemas con el paladar blando o la nariz.</li>
                                        <li><strong>Rinofonía hiponasal:</strong> Voz nasal insuficiente causada por obstrucciones nasales o problemas con el paladar blando.</li>
                                    </ul>
                                    
                                    <p><strong>Otros trastornos de la voz:</strong></p>
                                    <ul>
                                        <li><strong>Voz temblorosa:</strong> Voz inestable y temblorosa que puede ser causada por estrés, ansiedad o trastornos neurológicos.</li>
                                        <li><strong>Voz áspera:</strong> Voz rasposa y ronca que puede ser causada por sequedad, irritación o lesiones en las cuerdas vocales.</li>
                                        <li><strong>Voz baja:</strong> Voz que suena demasiado grave y profunda, lo que puede ser causado por problemas hormonales o anatómicos.</li>
                                        <li><strong>Voz aguda:</strong> Voz que suena demasiado alta y fina, lo que puede ser causado por problemas hormonales o anatómicos.</li>
                                    </ul>
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>   
                </ul>
                <div [ngbNavOutlet]="nav" class=" mt-2 col-md-12"></div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!-- End -->
<!-- Start -->
<!--end section-->
<section class="section" style="padding-top: 20px ; padding-bottom:20px ;">    
   
    <div class="container my-1">

        <h2>Nuestro Centro Médico en Bilbao cuenta además con otras especialidades médicas</h2>

        <div class="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-4">
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/logopeda-bilbao/11_Link_Oftalmologia_bilbao.webp" class="card-img-top" alt="Oftalmología Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/oftalmologo-bilbao" target="_blank">Oftalmología Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/logopeda-bilbao/12_Link_Psicologia_bilbao.webp" class="card-img-top" alt="Psicología Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/psicologo-bilbao" target="_blank">Psicología Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/logopeda-bilbao/13_Link_fisioterapia_bilbao.webp" class="card-img-top" alt="Fisioterapia Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/fisioterapeuta-bilbao" target="_blank">Fisioterapia Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/logopeda-bilbao/14_Link_nutrición_bilbao.webp" class="card-img-top" alt="Nutrición Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/nutricionista-bilbao" target="_blank">Nutrición Bilbao</a></h5>                
              </div>
            </div>
          </div>
        </div>
      </div>



    </section>
<!--end section-->


<!-- End -->

<!-- Start -->
<div class="container-fluid px-0">
    <div class="py-5 bg-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7">
                    <div class="section-title bg-white text-dark rounded border p-2 mb-3">
                        <div class="d-flex">
                            <a class="logo" routerLink="/index">
                                <img src="  assets/images/medicos-billbao-logopeda.png" class="l-dark logo-light-mode" class="img-fluid" alt="Nutricionista Bilbao" loading="lazy">        
                              </a> 
                            <div class="flex-1 ms-md-4 ms-3">
                                <h4 class="fw-bold text-dark mb-1">Logopedia Bilbao</h4>
                                <p class="text-dark-50 mb-0">Tratamos todos los problemas del habla</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-5 mt-4 mt-sm-0">
                    <div class="text-md-end ms-5 ms-sm-0">
                        <a href="https://medicosbilbao.com" class="btn btn-primary me-2 me-lg-2 me-md-0 my-2"
                            data-bs-toggle="modal" data-bs-target="#appointment-request"><i
                                class="uil uil-file-alt"></i> Descubre más sobre Médicos Bilbao</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end div-->  

</div>


<div class="container">
	<div class="row g-0" >		
    <div class="nb-form">
    <p class="title"><strong>Pide tu cita aquí</strong></p>
    <img src="https://medicosbilbao.com/img/logocuadrado.png" alt="Cita Logopeda  Bilbao" class="user-icon" >
    <p class="message" ><b>Médicos Bilbao: </b>Infórmate sin compromiso. Puedes contactar con nosotros a través de estos enlaces </p>	
	<ul class="list-group">
  <li class="list-group-item"><i class="fab fa-whatsapp"></i> <a href="https://api.whatsapp.com/send?phone=+606573238&text=Hola,%20estoy%20interesado%20en%20pedir%20una%20cita%20...%20" target="_blank"> 606 57 32 38</a><br>(solo whatsapp) </li>
  <li class="list-group-item"><i class="fas fa-phone"></i> <a href="tel:+34944648357" style="text-decoration:none"> 944 648 357</a></li>
  <li class="list-group-item"><i class="fas fa-globe"></i><a href="https://www.doctoralia.es/clinicas/especialistas-medicos-bilbao" rel="nofollow" target="_blank" class="reserva"> Reservar cita</a></li>  
  <li class="list-group-item"><i class="fa fa-envelope" aria-hidden="true"></i> <a href="https://medicosbilbao.com/contactar" id="correo"> Correo eléctrónico</a></li>
  </ul>    
  </div>
	</div>
</div>	

<!--end container-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-index-hospital',
  templateUrl: './index-hospital.component.html',
  styleUrls: ['./index-hospital.component.css']
})

/***
 * Hospital Component
 */
export class IndexHospitalComponent implements OnInit {

  active = 'top';
   // Set Topbar Option
   Menuoption = 'center';
  Settingicon = true
  
  

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
  };

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
